import {
  encrypt,
  decrypt,
  readMessage,
  createMessage,
  DecryptMessageResult,
} from 'openpgp';

type Response = {
  // TODO: this shouldn't be any
  data: any;
  status: number;
};

export const randomString = (): string => {
  let text = '';
  const possible =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  for (let i = 0; i < 22; i++) {
    text += possible.charAt(randomInt(0, possible.length));
  }
  return text;
};

const randomInt = (min: number, max: number): number => {
  const byteArray = new Uint8Array(1);
  window.crypto.getRandomValues(byteArray);

  const range = max - min;
  const maxRange = 256;
  if (byteArray[0] >= Math.floor(maxRange / range) * range) {
    return randomInt(min, max);
  }
  return min + (byteArray[0] % range);
};

export const backendDomain = process.env.REACT_APP_BACKEND_URL
  ? `${process.env.REACT_APP_BACKEND_URL}`
  : '';

export const postSecret = async (body: any): Promise<Response> => {
  return post(backendDomain + '/secret', body);
};

export const uploadFile = async (body: any): Promise<Response> => {
  return post(backendDomain + '/file', body);
};

const post = async (url: string, body: any): Promise<Response> => {
  const request = await fetch(url, {
    body: JSON.stringify(body),
    method: 'POST',
  });
  return { data: await request.json(), status: request.status };
};

export const decryptMessage = async (
  data: string,
  passwords: string,
  format: 'utf8' | 'binary',
): Promise<DecryptMessageResult> => {
  return decrypt({
    message: await readMessage({ armoredMessage: data }),
    passwords,
    format,
  });
};

export const encryptMessage = async (data: string, passwords: string) => {
  return encrypt({
    message: await createMessage({ text: data }),
    passwords,
  });
};

export function isErrorWithMessage(
  error: unknown,
): error is { message: string } {
  return (
    typeof error === 'object' &&
    error !== null &&
    'message' in error &&
    typeof (error as Record<string, unknown>).message === 'string'
  );
}

export const acceptFileTypes = {
  'image/*': [],
  'text/*': ['.txt', '.log', '.csv', '.yml', '.yaml', '.xml'],
  'application/json': ['.json'],
  'application/xml': ['.xml'],
  'application/zip': ['.zip'],
  'application/pdf': ['.pdf'],
  'application/msword': ['.doc'],
  'application/vnd.ms-excel': ['.xls'],
  'application/vnd.ms-powerpoint': ['.ppt'],
  'application/vnd.visio': ['.vsd', '.vsdx'],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
  'application/x-x509-ca-cert': ['.cer', '.crt', '.der'],
  'application/x-pkcs12': ['.p12', '.pfx'],
  'application/pkcs7-mime': ['.p7b', '.p7c'],
  'application/pkix-crl': ['.crl'],
  'application/x-pkcs8': ['.key'],
  'application/pkcs10': ['.csr'],
  'application/octet-stream': ['.jks'],
  'application/x-x509-user-cert': ['.pem']
}

export function getFileExtension(fileName: string) {
  return fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 1);
}

export function isSecureFile(fileName: string) {
  const allowedExtensions = Object.values(acceptFileTypes).flat();
  const fileExtension = getFileExtension(fileName);
  return allowedExtensions.includes(fileExtension);
}

export const fetcher = async (url: string) => {
  const request = await fetch(url);
  if (!request.ok) {
    throw new Error('Failed to fetch var');
  }
  return await request.text();
};

export default randomString;
